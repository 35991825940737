<template>
  <div v-if="$role(['director', 'service_advisor', 'head_operation'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4">
        <v-card :class="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center justify-center px-4 fill-width caption font-weight-bold py-2" dark outlined @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? ACCOUNT_SHOW() : false">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Customer
        </v-card>``
      </v-col>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4 px-2">
        <v-card :class="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold mr-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? ExportExcel(true) : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          Export
        </v-card>
        <v-card :class="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold ml-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? ExportExcel('all') : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          All
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-4 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search (name, email, phone)"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-4 white">
        <div class="px-4 pl-sm-0 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model.number="customerFilter"
            :items="[{ name: 'All Type', value: 1000 }, { name: 'Default', value: 0 }, { name: 'Business', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Customer Type"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pt-sm-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <v-icon class="mr-1">
                mdi-barcode
              </v-icon>
              <span class="font-weight-bold caption">
                RDMS/CUSTOMER/{{ item.id }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.vehicle_count`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                {{ item.vehicle_count }}
              </span>
              <v-icon class="ml-1" small>
                mdi-car-wrench
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex align-center">
                <div class="text-capitalize font-weight-bold">
                  {{ item.name }}
                </div>
                <v-badge
                  v-if="parseInt(item.type)"
                  bordered
                  color="warning"
                  icon="mdi-shield-car"
                  overlap
                >
                  <v-chip class="ml-1" outlined small color="purple" dark>
                    Business
                  </v-chip>
                </v-badge>
              </div>
            </div>
          </template>
          <template v-slot:[`item.birthday`]="{ item }">
            <div>
              {{ item.birthday ? $localDT(item.birthday, 'display') : '-' }}
            </div>
          </template>
          <template v-slot:[`item.store_name`]="{ item }">
            <div>
              {{ item.store_name }}
            </div>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <div>
              {{ item.role }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['service_advisor', 'head_operation'], $store.state.user.role)" :color="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'orange' : 'grey'" depressed small dark class="rounded text-capitalize" @click.prevent="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? ACCOUNT_SHOW(item) : false">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
              <v-btn v-if="$role(['director', 'service_advisor', 'head_operation'], $store.state.user.role)" :color="$role(['service_advisor', 'head_operation'], $store.state.user.role) ? 'indigo' : false" depressed small dark class="rounded text-capitalize ml-2" @click.prevent="$role(['director', 'service_advisor', 'head_operation'], $store.state.user.role) ? ACCOUNT_PRICE_SHOW(item) : false">
                <v-icon small class="mr-2">
                  mdi-account-cash-outline
                </v-icon>
                Pricing
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAccount.show"
      persistent
      scrollable
      max-width="340"
    >
      <v-card v-if="dialogAccount.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogAccount.data.id ? 'Update Customer' : 'Create New Customer' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 450px;">
          <v-text-field
            v-model="dialogAccount.data.name"
            outlined
            dense
            hide-details
            label="Name"
            class="mb-4 rounded-0"
          />
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <v-text-field
                v-model="dialogAccount.data.ktp"
                outlined
                dense
                hide-details
                label="NIK/KTP"
                class="mb-4 rounded-0"
              />
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-text-field
                v-model="dialogAccount.data.npwp"
                outlined
                dense
                hide-details
                label="NPWP"
                class="mb-4 rounded-0"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="dialogAccount.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogAccount.data.phone"
            outlined
            dense
            hide-details
            label="No.HP / Whatsapp"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-select
            v-model.number="dialogAccount.data.type"
            :items="[
              { name: 'Customer', value: 0 },
              { name: 'Business', value: 1 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Customer Type"
          />
          <v-select
            v-model.number="dialogAccount.data.status"
            :items="[
              { name: 'Active', value: 1 },
              { name: 'Not Active', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Account Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogAccount.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="ACCOUNT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAccountPrice.show"
      persistent
      scrollable
      max-width="475"
    >
      <v-card v-if="dialogAccountPrice.data" >
        <v-card-title v-if="dialogAccountPrice.detail" class="body-1 justify-space-between">
          <div class="d-flex flex-column justify-start">
            Custom Price
            <div class="caption">
              ID: {{ dialogAccountPrice.detail.id }}, Name: {{ dialogAccountPrice.detail.name || '-' }}
            </div>
          </div>
          <div class="d-flex align-center">
            <v-btn v-if="dialogAccountPrice.data.length" depressed class="rounded-pill caption text-capitalize mr-1" small color="red" dark @click.prevent="ImportFileExcelClear">
              <v-icon class="mr-1" small>
                mdi-trash
              </v-icon>
              Clear All
            </v-btn>
            <v-btn depressed class="rounded-pill caption text-capitalize" small color="primary" dark @click.prevent="ImportFileExcel">
              <v-icon class="mr-1" small>
                mdi-microsoft-excel
              </v-icon>
              Import
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-3 py-2" style="max-height: 90vh;">
          <div v-if="!dialogAccountPrice.data.length" class="pa-8 text-center caption grey--text">
            No data
          </div>
          <div v-else>
            <div
              v-for="(p, iP) in dialogAccountPrice.data"
              :key="'item-price-' + iP"
              class="d-flex align-center justify-space-between px-3 py-2 grey lighten-4 my-1"
            >
              <div>
                {{ p.product_name || '-' }} {{ p.name || '-' }}
                <div class="caption">
                  {{ p.item_fullname }}
                </div>
              </div>
              <div>
                {{ $price(p.price) }}
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogAccountPrice.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
let searchInterval = null
export default {
  name: 'Customer',
  data: () => ({
    birthdayMenu: false,
    activePicker: null,
    customerFilter: 1000,
    headers: [
      {
        text: 'CUST.ID',
        value: 'id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email' },
      { text: 'Vehicles', value: 'vehicle_count' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogAccount: {
      show: false,
      data: null
    },
    dialogAccountPrice: {
      show: false,
      data: [],
      detail: null
    },
    dialogAccountPriceUpdate: {
      show: false,
      data: null
    }
  }),
  computed: {
  },
  watch: {
    birthdayMenu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    customerFilter (v) {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ACCOUNTS_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.ACCOUNTS_GET(this.tableSearch)
      }
    },
    options () {
      this.ACCOUNTS_GET(this.tableSearch)
    }
  },
  mounted () {
    this.ACCOUNTS_GET()
  },
  methods: {
    async ExportExcel (isAll) {
      if (!isAll) {
        return false
      }
      const requestData = await this.ACCOUNTS_GET(this.tableSearch, isAll)
      if (requestData && requestData.status && requestData.data.data.data) {
        const headers = ['ID', 'TYPE', 'NAME', 'EMAIL', 'PHONE', 'VEHICLES', 'STATUS', 'CREATED']
        const rows = []
        const data = requestData.data.data.data || []
        for (let d = 0; d < data.length; d++) {
          const newRow = data[d]
          rows.push([
            {
              type: Number,
              value: newRow.id
            },
            {
              type: String,
              value: parseInt(newRow.type) ? 'Business' : 'Default'
            },
            {
              type: String,
              value: (newRow.name || '-')
            },
            {
              type: String,
              value: (newRow.email || '-')
            },
            {
              type: String,
              value: (newRow.phone || '-')
            },
            {
              type: Number,
              value: parseInt(newRow.vehicle_count) || 0
            },
            {
              type: String,
              value: parseInt(newRow.status) ? 'Active' : 'Not Active'
            },
            {
              type: Date,
              format: 'yyyy-mm-dd hh:mm:ss',
              value: new Date(newRow.created)
            }
          ])
        }
        await this.$CreateExcelFile({
          headers: headers,
          rows: rows
        }, 'customer-' + (isAll === 'all' ? 'all-' : 'filtered-') + this.$localDT(null, 'datedefault') + '.xlsx')
      }
    },
    async ImportFileExcelClear () {
      if (!confirm('Clear this pricing data ?')) {
        return false
      }
      await this.$store.dispatch('product/PRODUCT_ITEM_PRICING_CUSTOMER_CLEAR', this.dialogAccountPrice.detail.id)
        .then(() => {
          this.ACCOUNT_PRICE_SHOW(this.dialogAccountPrice.detail)
        })
    },
    ImportFileExcel () {
      if (!confirm('Import data will add new and replace existing data!')) {
        return false
      }
      let el = document.getElementById('import-price-excel')
      if (el) {
        el.parentNode.removeChild(el)
      }
      el = document.createElement('input')
      el.setAttribute('id', 'import-price-excel')
      el.setAttribute('class', 'file-upload')
      el.setAttribute('type', 'file')
      el.addEventListener('change', (event) => {
        const xlsxfile = event.target.files ? event.target.files[0] : null
        readXlsxFile(xlsxfile).then((rows) => {
          const data = []
          for (let r = 0; r < rows.length; r++) {
            const newR = {}
            newR.sku = rows[r][0] || ''
            newR.price = parseInt(rows[r][1]) || 0
            newR.product_spec = parseInt(rows[r][2])
            newR.customer = parseInt(rows[r][3]) || parseInt(this.dialogAccountPrice.detail.id) || 0
            if (newR.price) {
              data.push(newR)
            }
          }
          this.$store.dispatch('product/PRODUCT_ITEM_PRICING_CUSTOMER_ADD', data)
            .then((res) => {
              if (res) {
                if (res.status) {
                  this.ACCOUNT_PRICE_SHOW(this.dialogAccountPrice.detail)
                  if (res.data.data) {
                    this.$store.dispatch('TOAST', { show: true, message: 'Berhasil di import!' })
                  } else {
                    this.$store.dispatch('TOAST', { show: true, message: 'Ops, try again!' })
                  }
                }
              }
            })
        })
      })
      el.click()
    },
    async ACCOUNT_PRICE_SHOW (customer) {
      this.dialogAccountPrice.detail = Object.assign({}, customer)
      this.dialogAccountPrice.show = true
      await this.$store.dispatch('product/PRODUCT_ITEM_PRICING_CUSTOMER', customer.id)
        .then((res) => {
          if (res) {
            if (res.status) {
              this.dialogAccountPrice.data = res.data.data || []
            }
          }
        })
    },
    saveBirthday (date) {
      this.$refs.birthdayMenu.save(date)
    },
    ACCOUNT_SHOW (account) {
      if (account) {
        this.dialogAccount.data = Object.assign({}, account)
      } else {
        this.dialogAccount.data = {
          id: null,
          name: '',
          ktp: '',
          npwp: '',
          email: '',
          phone: '',
          type: 0,
          status: 1
        }
      }
      this.dialogAccount.show = true
    },
    async ACCOUNTS_GET (q, dataExcel) {
      let customerFilter = '&type=0'
      if (this.customerFilter === 1000) {
        customerFilter = '&type=1000'
      } else if (this.customerFilter === 1) {
        customerFilter = '&type=1'
      }
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const withSortBy = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      const pageRow = (parseInt(page) > 0 ? '&page=' + page : '')
      const limitRow = (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')
      const withStatus = (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')
      if (dataExcel) {
        const exportPage = '&page=1'
        const exportLimit = dataExcel === 'all' ? '&limit=1000000000' : limitRow
        const query = '?c=' + exportPage + exportLimit + withSortBy
        const exportQuery = dataExcel === 'all' ? '' : (query + (q ? ('&q=' + q) : '') + withStatus + customerFilter)
        return await this.$store.dispatch('customer/CUSTOMER_SEARCH', exportQuery).then(res => res)
      } else {
        const query = '?c=' + pageRow + limitRow + withSortBy
        this.tableLoading = true
        await this.$store.dispatch('customer/CUSTOMER_SEARCH', query + (q ? ('&q=' + q) : '') + withStatus + customerFilter)
          .then((res) => {
            if (res.status) {
              this.table = res.data.data.data
              this.tableTotal = parseInt(res.data.data.total) || 0
            } else {
              this.table = []
              this.tableTotal = 0
            }
            this.tableLoading = false
          })
      }
    },
    ACCOUNT_PROCESS () {
      const processedAccount = Object.assign({}, this.dialogAccount.data)
      if (!this.$validateName(processedAccount.name)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!this.$validateEmail(processedAccount.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
        return false
      }
      if (!this.$validatePhone(processedAccount.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP tidak valid!' })
        return false
      }
      processedAccount.phone = this.$reformatPhone(processedAccount.phone)
      processedAccount.role = 'customer'
      this.$store.dispatch('customer/CUSTOMER_PROCESS_BY_ADMIN', processedAccount)
        .then((res) => {
          if (res.status) {
            if (res.data.data.id) {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedAccount.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    }
  }
}
</script>
